
import React from "react";
import style from "./App.module.less";

export default function App() {

  // 接收 iframe 发送的消息, 返回源站点的 hostname
  React.useEffect(() => {
    function handleReceiveMessage(event) {
      if (event.data.type === 'kts-get-source') {
        event.source.postMessage({
          type: 'kts-get-source-callback',
          source: window.location.hostname,
        }, event.origin);
      }
    }

    window.addEventListener('message', handleReceiveMessage);

    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, []);

  return (
    <iframe className={style.app} src={`//debugging.dev.kingxunlian.com:3000`} title="Debugging App" />
  )
}
